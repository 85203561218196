<template>
  <b-card class="control-border-color d-flex flex-row">
    <h4 class="mt-1 mb-1 ml-1" v-if="this.showOcorrenciasCashu">Ocorrencias</h4>
    <b-tabs v-if="!showOcorrenciasCashu">
      <b-tab title="Em aberto">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <flat-pickr
                v-model="open.data_abertura"
                class="form-control bg-white mr-1 assignor-selector"
                :config="config"
                placeholder="Data de abertura"
              />
              <b-form-input
                class="mr-1 assignor-selector"
                v-model="open.extrato"
                placeholder="Número do título"
              />
              <v-select
                v-model="open.assignor"
                :options="assignors"
                placeholder="Cedente"
                class="assignor-selector d-flex flex-column mr-1"
              />
              <v-select
                v-model="open.reason"
                :options="reasons"
                class="mr-1 w-240px"
                :reduce="(option) => option.value"
                placeholder="Motivo"
              />
            </div>
            <b-button variant="flat-secondary" @click="clearOpenForm">
              <feather-icon icon="RefreshCwIcon" size="18" class="text-secondary" />
            </b-button>
          </div>
          <div class="d-flex flex-column align-items-end">
            <span> Operações em aberto </span>
            <h3>{{ openTotalValue }}</h3>
          </div>
        </div>
        <datatable
          ref="open"
          :no-body="true"
          :customFields="openFields"
          @row-action="rowAction"
          :serviceUrl="openServiceUrl"
        >
        </datatable>
      </b-tab>
      <b-tab title="Resolvido">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <flat-pickr
              v-model="solved.data_abertura"
              class="form-control bg-white mr-1 assignor-selector"
              :config="config"
              placeholder="Data de abertura"
            />
            <b-form-input
              class="mr-1 assignor-selector"
              v-model="solved.extrato"
              placeholder="Extrato/Título"
            />
            <v-select
            v-model="solved.assignor"
            :options="assignors"
            placeholder="Cedente"
            class="assignor-selector d-flex flex-column mr-1"
            />
            <v-select
              v-model="solved.reason"
              :options="reasons"
              class="mr-1 w-240px"
              :reduce="(option) => option.value"
              placeholder="Motivo"
            />
            <b-button variant="flat-secondary" @click="clearSolvedForm">
              <feather-icon icon="RefreshCwIcon" size="18" class="text-secondary" />
            </b-button>
          </div>
          <div class="d-flex flex-column align-items-end">
            <span> Operações em aberto </span>
            <h3>{{ openTotalValue }}</h3>
          </div>
        </div>
        <div>
          <datatable
            ref="solved"
            :customFields="solvedCustomFields"
            :no-body="true"
            :serviceUrl="solvedServiceUrl"
          >
          </datatable>
        </div>
      </b-tab>
    </b-tabs>
    <datatable
      ref="solved"
      :customFields="solvedCustomFields"
      :no-body="true"
      :serviceUrl="CashuOcorrenciasServiceUrl"
      v-if="showOcorrenciasCashu"
    >
    </datatable>
  </b-card>
</template>

<script>
import { BTab, BTabs, BCard, BButton, BFormInput } from 'bootstrap-vue'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import {
  moneyFormatDatatable,
  reasonFormatDatatable,
  brazilianFormattedDate,
  tipoDeOcorrenciaDatatable,
  statusImportacaoFormatter,
} from '@core/comp-functions/data_visualization/datatable'

export default {
  name: 'ExtractIndex',
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    VSelect,
    Datatable,
    flatPickr,
    BFormInput,
  },
  props: {
    showOcorrenciasCashu: {
      type: Boolean,
      default: false,
    },
    remessaID: {
      type: Number,
    }
  },
  data() {
    return {
      assignors: [],
      openTotalValue: '',
      openServiceUrl: '/recebiveis/ocorrencias/pendentes',
      solvedServiceUrl: '/recebiveis/ocorrencias/resolvidas',
      CashuOcorrenciasServiceUrl: '',
      open: {
        data_abertura: '',
        extrato: '',
        reason: '',
        assignor: ''
      },
      solved: {
        data_abertura: '',
        extrato: '',
        reason: '',
        assignor: ''
      },
      reasons: [
        { value: 'cancelado', label: 'Cancelado' },
        { value: 'reducao_valor', label: 'Redução de valor' },
        { value: 'alongamento_prazo', label: 'Alongamento prazo' },
        { value: 'pago', label: 'Pago' },
        { value: 'atraso', label: 'Atraso' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      openFields: [
        {
          label: 'Ocorrência',
          key: 'id',
        },
        {
          label: 'Título',
          key: 'titulo.numero',
        },
        {
          label: 'Cedente',
          key: 'cedente.nome',
        },
        {
          label: 'Abertura',
          key: 'data_referencia',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor da ocorrência',
          key: 'valor',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Motivo',
          key: 'motivo',
          formatter: reasonFormatDatatable,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: 'Detalhes da ocorrência',
          key: 'detalhes',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: '',
          key: 'more',
        },
      ],
      solvedCustomFields: [],
      solvedCustomFieldsDefault: [
        {
          label: 'Ocorrência',
          key: 'id',
        },
        {
          label: 'Título',
          key: 'titulo.numero',
        },
        {
          label: 'Abertura',
          key: 'data_referencia',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Resolução',
          key: 'data_referencia',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor da ocorrência',
          key: 'valor',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Motivo',
          key: 'motivo',
          formatter: reasonFormatDatatable,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: 'Detalhes da ocorrência',
          key: 'detalhes',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
      ],
      solvedCustemFieldsCashu: [
        {
          label: 'Título',
          key: 'titulo.numero',
        },
        {
          label: 'Abertura',
          key: 'data_referencia',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Resolução',
          key: 'data_referencia',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor da ocorrência',
          key: 'valor',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Motivo',
          key: 'tipo',
          formatter: tipoDeOcorrenciaDatatable,
        },
        {
          label: 'Detalhes da ocorrência',
          key: 'status_importacao',
          formatter: statusImportacaoFormatter,
        },
      ],
    }
  },
  beforeMount() {
    if(this.showOcorrenciasCashu) {
      this.solvedCustomFields = this.solvedCustemFieldsCashu
    } else {
      this.solvedCustomFields = this.solvedCustomFieldsDefault
    }
  },
  mounted() {
    this.CashuOcorrenciasServiceUrl = `recebiveis/ocorrencias/cashu/${this.remessaID}`
    this.solvedCustomFields = this.solvedCustemFieldsCashu
    if(this.showOcorrenciasCashu) {
      this.solvedCustomFields = this.solvedCustemFieldsCashu
      this.$refs.solved.customFields = this.solvedCustemFieldsCashu
    } else {
      this.solvedCustomFields = this.solvedCustomFieldsDefault
      this.$refs.solved.customFields = this.solvedCustomFieldsDefault
    }
    this.updateOpenTotalValue()
    this.getAssignors()
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        value: assignor.id,
        label: assignor.nome,
      }))
    },
    moneyFormatDatatable,
    async updateOpenTotalValue() {
      try {
        const data = await this.$store.dispatch('admin/openTotalValue')
        this.openTotalValue = this.moneyFormatDatatable(parseFloat(data.total_em_aberto))
      } catch (error) {}
    },
    rowAction({ action, payload }) {
      const actions = {
        repurchase: 'repurchaseConfirm',
      }
      return this[actions[action]](payload)
    },
    repurchaseConfirm(payload) {
      this.$swal({
        title: 'Recompra de título',
        text: 'Executar esta ação tornará este título recomprado. Você tem certeza que deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Recomprar título',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) this.repurchase(payload)
      })
    },
    async repurchase(payload) {
      try {
        await this.$store.dispatch('admin/repurchase', payload)
        this.$swal({
          title: 'Título recomprado!',
          text: 'O título foi recomprado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.$refs.open.getData()
        this.$refs.solved.getData()
      } catch (error) {}
    },
    clearOpenForm() {
      this.open = {
        data_abertura: '',
        extrato: '',
        reason: '',
        assignor: ''
      }
    },
    clearSolvedForm() {
      this.solved = {
        data_abertura: '',
        extrato: '',
        reason: '',
        assignor: ''
      }
    },
  },
  computed: {
    openParams() {
      let params = {}

      if (this.open.data_abertura) {
        const dates = this.open.data_abertura.split(' até ')
        if (dates.length === 1) {
          params.data_abertura_de = dates[0].split('/').reverse().join('-')
        } else {
          params.data_abertura_de = dates[0].split('/').reverse().join('-')
          params.data_abertura_ate = dates[1].split('/').reverse().join('-')
        }
      }
      if (this.open.reason) params.motivo = this.open.reason
      if (this.open.extrato) params.numero_titulo = this.open.extrato

      if (this.open.assignor) params.cedente_id = this.open.assignor.value
      
      return params
    },
    solvedParams() {
      let params = {}

      if (this.solved.data_abertura) {
        const dates = this.solved.data_abertura.split(' até ')
        if (dates.length === 1) {
          params.data_abertura_de = dates[0].split('/').reverse().join('-')
        } else {
          params.data_abertura_de = dates[0].split('/').reverse().join('-')
          params.data_abertura_ate = dates[1].split('/').reverse().join('-')
        }
      }
      if (this.solved.reason) params.motivo = this.solved.reason
      if (this.solved.extrato) params.numero_titulo = this.solved.extrato

      if (this.solved.assignor) params.cedente_id = this.solved.assignor.value

      return params
    },
  },
  watch: {
    openParams(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.open.updateFilterValues(newValue)
    },
    solvedParams(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.solved.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';
.assignor-selector {
  max-width: 200px;
}
.w-240px {
  width: 240px;
}
</style>
